<template>
  <div>
    <v-overlay :value="overlay"
      ><v-progress-circular
        :size="70"
        :width="7"
        color="green"
        indeterminate
      ></v-progress-circular
    ></v-overlay>
    <v-container fluid class="px-6 py-6">
      <v-card class="card-shadow border-radius-xl">
        <div class="ma-5">
          <!-- Style Fix -->
          <v-row>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <label class="text-md font-weight-bolder ms-1 mandatory"
                >GST Type</label
              >
              <v-select
                v-model="gstType"
                @change="typeChange"
                :items="gstTypes"
                item-text="type"
                item-value="type"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-2
                "
                dense
                flat
                filled
                solo
                height="43"
                persistent-hint
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="3" v-show="showLgst">
              <label class="text-md font-weight-bolder ms-1 mandatory"
                ><span class="red--text"><strong>* </strong></span>CGST</label
              >
              <v-text-field
                v-model.number="gst.cgst"
                hide-details="auto"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-2
                "
                dense
                flat
                filled
                solo
                height="43"
                persistent-hint
                :disabled="isDisable"
              ></v-text-field
            ></v-col>
            <v-col cols="12" md="3" v-show="showLgst"
              ><label class="text-md font-weight-bolder ms-1 mandatory"
                ><span class="red--text"><strong>* </strong></span>SGST</label
              >
              <v-text-field
                v-model.number="gst.sgst"
                hide-details="auto"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-2
                "
                dense
                flat
                filled
                solo
                height="43"
                persistent-hint
                :disabled="isDisable"
              ></v-text-field></v-col
            ><v-col cols="12" md="3" v-show="showIgst"
              ><label class="text-md font-weight-bolder ms-1 mandatory"
                ><span class="red--text"><strong>* </strong></span>IGST</label
              >
              <v-text-field
                v-model.number="gst.igst"
                hide-details="auto"
                class="
                  input-style
                  font-size-input
                  text-light-input
                  placeholder-light
                  input-icon
                  mt-2
                "
                dense
                flat
                filled
                solo
                height="43"
                persistent-hint
                :disabled="isDisable"
              ></v-text-field
            ></v-col>
            <v-col class="d-flex justify-end mx-5" v-show="showEdit"
              ><v-btn
                @click="enableEdit"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                v-show="isDisable"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  mt-7
                "
                >Edit</v-btn
              >

              <v-btn
                @click="saveGst"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                v-show="!isDisable"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  mt-7
                "
                >Save</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="mb-5 mt-5"> </v-row>
        </div>
      </v-card>
      <v-card class="card-shadow border-radius-xl">
        <div class="ma-5">
          <v-form ref="frmFilter">
            <!-- Style Fix -->
            <v-row>
              <v-col></v-col>
            </v-row>
            <v-row class="mt-5" dense>
              <v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span
                  >Vendor</label
                >
                <v-autocomplete
                  v-model="vendor"
                  :items="vendors"
                  item-text="name"
                  item-value="id"
                  return-object
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Vendor"
                  :rules="[(v) => !!v || 'Vendor is Required']"
                  outlined
                  single-line
                  height="48"
                  @change="getSeason"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span
                  >Season</label
                >
                <v-autocomplete
                  return-object
                  v-model="season"
                  :items="seasons"
                  item-text="name"
                  item-value="id"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Season"
                  :rules="[(v) => !!v || 'Season is Required']"
                  outlined
                  single-line
                  height="48"
                  @change="getOffers"
                >
                </v-autocomplete> </v-col
              ><v-col cols="12" sm="4">
                <label class="text-md font-weight-bolder ms-1 mandatory"
                  ><span class="red--text"><strong>* </strong></span>Offer
                  No</label
                >
                <v-autocomplete
                  return-object
                  v-model="offer"
                  :items="offers"
                  item-text="offer_no"
                  color="rgba(0,0,0,.6)"
                  class="
                    input-style
                    font-size-input
                    text-light-input
                    placeholder-light
                    border-radius-md
                    select-style
                    mb-0
                    mt-5
                  "
                  placeholder="Select a Offer"
                  :rules="[(v) => !!v || 'Offer is Required']"
                  outlined
                  single-line
                  height="48"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
          <v-row>
            <v-col class="d-flex justify-end mx-5">
              <v-btn
                @click="close"
                elevation="0"
                :ripple="false"
                height="43"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-secondary
                  bg-light
                  py-3
                  px-6
                  mr-2
                "
                >Clear</v-btn
              >

              <v-btn
                @click="searchData"
                elevation="0"
                :ripple="false"
                height="43"
                dark
                class="
                  font-weight-bold
                  text-capitalize
                  btn-primary
                  bg-success
                  py-3
                  px-6
                "
                >Search</v-btn
              ></v-col
            >
          </v-row>
        </div>
      </v-card>

      <v-card class="card-shadow border-radius-xl mt-6" v-show="showTable">
        <v-card-text class="card-padding">
          <v-data-table
            v-model="reqData"
            :headers="headers"
            :items="tableData"
            item-key="id"
            show-select
            mobile-breakpoint="0"
            :footer-props="{
              'items-per-page-options': [100, 200, 500, -1],
            }"
            fixed-header
            disable-pagination
            hide-default-footer
            class="table"
          >
            <template v-slot:top>
              <v-toolbar flat class="mb-5">
                <v-row
                  ><v-col cols="3"
                    ><label class="text-md font-weight-bolder ms-1 mandatory"
                      ><span class="red--text"><strong>* </strong></span
                      >Date</label
                    >
                    <v-menu
                      ref="mnu_date"
                      v-model="mnu_date"
                      :close-on-content-click="false"
                      :return-value.sync="mnu_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="date"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details="auto"
                          class="
                            input-style
                            font-size-input
                            text-light-input
                            placeholder-light
                            input-icon
                          "
                          dense
                          flat
                          filled
                          solo
                          height="46"
                          placeholder="Date"
                          persistent-hint
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="green lighten-1"
                        header-color="primary"
                        v-model="date"
                        no-title
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="mnu_date = false">
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.mnu_date.save(date)"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col></v-row
                >

                <v-spacer> </v-spacer
                ><v-btn
                  elevation="0"
                  :ripple="false"
                  height="43"
                  dark
                  @click="submit"
                  class="
                    font-weight-bold
                    text-capitalize
                    btn-ls btn-primary
                    bg-success
                    py-3
                    px-6
                  "
                  >Submit
                </v-btn></v-toolbar
              >
            </template>
            <template v-slot:[`item.gp_date`]="{ item }">
              <span>
                {{ formatDate(item.gp_date) }}
              </span>
            </template>
            <template v-slot:[`item.exp_date`]="{ item }">
              <span>
                {{ formatDate(item.exp_date) }}
              </span>
            </template>
            <template v-slot:[`item.period_of_manufacture`]="{ item }">
              <span>
                {{ formatDate(item.period_of_manufacture) }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col offset-md="5"
              ><v-btn
                elevation="0"
                :ripple="false"
                height="43"
                dark
                @click="submit"
                class="
                  font-weight-bold
                  text-capitalize
                  btn-ls btn-primary
                  bg-success
                  py-3
                  px-6
                "
                >Submit
              </v-btn></v-col
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-container>
    <!-- Dialog Ware House -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card class="card-shadow border-radius-xl">
        <v-card-text class="card-padding">
          <v-form ref="frmSave">
            <v-container class="px-0">
              <v-row>
                <v-col
                  ><label class="text-md text-typo font-weight-bolder ms-1"
                    >Ware House</label
                  >

                  <v-autocomplete
                    return-object
                    v-model="companyWareHouse"
                    :items="companyWareHouses"
                    item-text="name"
                    color="rgba(0,0,0,.6)"
                    class="
                      input-style
                      font-size-input
                      text-light-input
                      placeholder-light
                      border-radius-md
                      select-style
                      mb-0
                      mt-5
                    "
                    :rules="[(v) => !!v || 'Warehouse is required']"
                    required
                    placeholder="Select Warehouse center"
                    outlined
                    single-line
                    height="48"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col align-self="center">
                  <v-btn
                    @click="confirm"
                    elevation="0"
                    :ripple="false"
                    height="43"
                    dark
                    class="
                      font-weight-bold
                      text-capitalize
                      btn-ls btn-primary
                      bg-success
                      py-3
                      px-6
                      mt-2
                    "
                    >Save
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Dialogue Save Confirm -->
    <v-dialog v-model="confirmDialog" max-width="500px" persistent>
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center">
          <p class="text-center">Do you want to Save With</p>
          <p class="text-center">{{ message }}?</p>
        </v-card-title>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="confirmCancel"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls bg-light py-3 px-6"
            >No</v-btn
          >

          <v-btn
            @click="confirmOk"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls btn-primary
              bg-success
              py-3
              px-6
            "
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import formatDate from "../../../../global-data/dateFormatter";
import api from "../api";
import masterApi from "../../../master/master-get-api";
export default {
  data() {
    return {
      showTable: false,
      gstTypes: [
        {
          type: "GST",
        },
        { type: "IGST" },
      ],
      gstType: "Local",
      showLgst: true,
      showIgst: false,
      showEdit: true,
      message: "",
      vendors: [],
      vendor: null,
      season: null,
      seasons: [],
      offer: null,
      offers: [],

      overlay: false,
      tableData: [],
      reqData: [],
      companyWareHouses: [],
      companyWareHouse: null,
      // bidValues: [{ bidOne: "" }],
      editedData: [],
      confirmDialog: false,
      dialog: false,
      gst: {
        cgst: 0,
        id: 0,

        igst: 0,
        sgst: 0,
      },
      isDisable: true,
      headers: [
        {
          text: "Offer Code",
          value: "offer_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Lot No",
          value: "lot_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Tea Type",
          value: "tea_type",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Mark",

          value: "mark_name",

          class: "text-secondary font-weight-bolder opacity-10",

          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Grade",

          value: "grade",

          class: "text-secondary font-weight-bolder opacity-10",

          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Invoice NO",
          value: "invoice_number",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        // {
        //   text: "Item Name",
        //   value: "item_name",
        //   class: "text-secondary font-weight-bolder opacity-10",
        //   align: "center",
        // },

        {
          text: "No Of Packages",
          value: "no_of_packages",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Gross wt/Bag",
          value: "gross_net_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Net weight(KGs)",
          value: "net_kgs",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },

        {
          text: "Invoice Weight(KGs)",
          value: "invoice_weight",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Sample/Shortage",
          value: "",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Net Qty",
          value: "",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Offer Price",
          value: "offer_price",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },

        {
          text: "Gp No",
          value: "gp_no",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Gp Date",
          value: "gp_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Buying Location",
          value: "buying_location",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Period Of Manufacture",
          value: "period_of_manufacture",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
        {
          text: "Expiry Date",
          value: "exp_date",
          class: "text-secondary font-weight-bolder opacity-10",
          align: "center",
          width: 150,
          sortable: false,
        },
      ],
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      mnu_date: false,
    };
  },
  created() {
    this.initialize();
  },
  methods: {
    async submit() {
      // console.log(this.reqData);
      if (this.reqData.length > 0) {
        console.log(this.reqData);

        this.dialog = true;
      } else {
        this.showWarningAlert("Please select Atleast one row");
      }
    },
    typeChange() {
      console.log(this.gstType);
      if (this.gstType == "GST") {
        this.showLgst = true;
        this.showEdit = true;
        this.showIgst = false;
      }
      if (this.gstType == "IGST") {
        this.showLgst = false;
        this.showEdit = true;
        this.showIgst = true;
      }
    },
    // taxCalc() {
    //   if (this.gst.igst > 0) {
    //     this.gst.cgst = 0;
    //     this.gst.sgst = 0;
    //   }
    //   if (this.gst.cgst > 0 || this.gst.sgst > 0) {
    //     this.gst.igst = 0;
    //   }
    // },
    formatDate(date) {
      if (date) {
        var d = new Date(date),
          month = "" + (d.getMonth() + 1),
          day = "" + d.getDate(),
          year = d.getFullYear();
        if (month.length < 2) month = "0" + month;
        if (day.length < 2) day = "0" + day;
        return [year, month, day].join("-");
      }
    },
    async confirm() {
      if (this.saveValidate()) {
        this.dialog = false;
        console.log("GST", this.gst.sgst, this.gst.cgst, this.gst.igst);
        if ((this.gst.sgst == 0 || this.gst.cgst == 0) && this.gst.igst == 0) {
          this.showWarningAlert("Please Save GST First");
        } else {
          let [gstValues] = await api.getGst();

          this.message = "";
          if (this.gstType == "GST") {
            if (
              gstValues.sgst == this.gst.sgst &&
              gstValues.cgst == this.gst.cgst &&
              gstValues.cgst > 0 &&
              gstValues.sgst > 0
            ) {
              this.message = ` SGST ${this.gst.sgst} % , CGST ${this.gst.cgst} %`;
              this.confirmDialog = true;
            } else {
              this.showWarningAlert("Please click save on GST First");
            }
          }
          if (this.gstType == "IGST") {
            if (gstValues.igst == this.gst.igst && gstValues.igst > 0) {
              this.message = ` IGST ${this.gst.igst} % `;
              this.confirmDialog = true;
            } else {
              this.showWarningAlert("Please click save on GST First");
            }
          }
        }
      }
    },
    confirmOk() {
      this.confirmDialog = false;
      this.save();
    },
    confirmCancel() {
      this.confirmDialog = false;
    },

    async save() {
      if (this.saveValidate()) {
        this.overlay = true;

        let req = {
          master: {
            vendor: this.vendor,
            purchase_type: "PRIVATE",
            company_warehouse: this.companyWareHouse,
            po_number: "",
            sale_date: this.date,
            gstType: this.gstType,
          },
          child: this.reqData,
        };
        try {
          // console.log(this.reqData);
          await api.addCompanyWareHouse(req);
          this.showSuccessAlert("PO Generation Request send");
          this.dialog = false;
          this.tableData = [];
          this.showTable = false;
        } catch (error) {
          this.showErrorAlert(error);
        } finally {
          this.overlay = false;
        }
      }
    },
    enableEdit() {
      this.isDisable = false;
    },
    async saveGst() {
      try {
        this.overlay = true;
        await api.saveGst(this.gst);
        this.showSuccessAlert("GST Saved");
        this.isDisable = true;
        this.overlay = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    searchValidation() {
      return this.$refs.frmFilter.validate();
    },
    saveValidate() {
      return this.$refs.frmSave.validate();
    },
    async initialize() {
      try {
        this.overlay = true;
        this.vendors = await masterApi.getPrivateVendors();
        this.companyWareHouses = await api.getWareHouse();
        let result = await api.getGst();
        this.gst.cgst = result[0].cgst;
        this.gst.sgst = result[0].sgst;
        this.gst.igst = result[0].igst;

        if (this.gst.cgst > 0 || this.gst.sgst > 0) {
          this.gstType = "GST";
          this.showLgst = true;
          this.showIgst = false;
        }
        if (this.gst.igst > 0) {
          this.gstType = "IGST";
          this.showIgst = true;
          this.showLgst = false;
        }

        this.gst.id = result[0].id;

        console.log(this.gst);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },

    async getSeason() {
      try {
        this.seasons = [];
        this.season = null;
        this.overlay = true;
        this.offers = [];
        this.offer = null;
        this.seasons = await masterApi.getSeasonByVendor(this.vendor.id);
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },

    async getOffers() {
      try {
        this.offers = [];
        this.offer = null;
        this.overlay = true;
        this.offers = await masterApi.getOffersByvendorSeason(
          this.vendor.id,
          this.season.id
        );
      } catch (error) {
      } finally {
        this.overlay = false;
      }
    },

    async searchData() {
      this.showTable = false;
      if (this.searchValidation()) {
        this.tableData = [];
        this.reqData = [];
        this.overlay = true;
        try {
          //Fill Table
          this.tableData = await api.getConfirmedBid(
            this.vendor.id,
            this.season.id,
            this.offer.offer_no
          );
          if (this.tableData.length > 0) {
            this.showTable = true;
          } else {
            this.noDataFoundAlert("No Data Found...!");
          }
          console.log("this.tableData", this.tableData);
        } catch (error) {
        } finally {
          this.overlay = false;
        }
      }
    },
    edit(item) {
      this.editedData = [];
      this.editedData.push(item);
    },

    checkData(item) {
      let idx = this.editedData.findIndex((el) => el.id == item.id);
      if (idx > -1) return false;
      else return true;
    },
    checkVisibility(item) {
      // console.log(item);
      if (item.bid.length == 0) {
        return true;
      } else {
        let idx = item.bid.findIndex((el) => {
          console.log(el);
          return el.last_status == "Reject";
        });
        console.log(idx);
        if (idx > -1 && item.bid.length < 3) {
          return true;
        } else {
          return false;
        }
      }
    },
    close() {
      this.vendor = null;
      this.season = null;
      this.seasons = [];
      this.offers = [];
      this.offer_no = null;
      this.$refs.frmFilter.resetValidation();
    },
    noDataFoundAlert(message) {
      this.$swal({
        text: message,
        showConfirmButton: false,
        timer: 3000,
      });
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        type: "success",
        timer: 3000,
        icon: "success",
        showConfirmButton: false,
      });
    },

    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        type: "error",
        showConfirmButton: true,

        icon: "error",
      });
    },
    showWarningAlert(message) {
      this.$swal({
        title: "warning",
        text: message,
        showConfirmButton: false,
        type: "warning",
        timer: 3000,
        icon: "warning",
      });
    },
  },
};
</script>

<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>